@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .animate-marquee {
    animation: marquee 15s linear infinite;
  }
}


